import Rest from '@/services/Rest';

/**
 * @typedef {ClientRegisterService}
 */
export default class ClientRegisterService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/client-register'
}
